<template>
  <div class="modal-overlay">
    <div class="modal-container">
      <div class="modal-content">
        <h2 class="modal-header">
          Proof of ID Request
        </h2>
        <div class="modal-info">
          <div>
            <span class="status-oval active-oval">
              Active
            </span>
          </div>
          <div>
            <strong>Request From:</strong> BANK AAA
          </div>
          <div>
            <strong>Requested At:</strong> {{ formatDateTime(request.createdAt) }}
          </div>
          <div class="button-container">
            <button
              class="accept-btn btn"
              @click="acceptRequest"
            >
              Trust
            </button>
            <button
              class="reject-btn btn"
              @click="rejectRequest"
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  emits: ['onClose'],
  methods: {
    ...mapActions('requests', ['sendDecision']),
    replaceString (s) {
      return s.replace('CognitoIdentityServiceProvider.', 'PID-BitsProof-')
    },
    formatDateTime (timestamp) {
      const date = new Date(timestamp)
      return date.toLocaleString()
    },
    acceptRequest () {
      this.sendDecision({ request: this.request, approval: true })
      this.$emit('onClose')
    },
    rejectRequest () {
      this.sendDecision({ request: this.request, approval: false })
      this.$emit('onClose')
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background-color: #fff;
  width: 50%;
  height: auto;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.modal-header {
  background-color: #5423e7;
  color: #fff;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
}

.modal-info {
  padding: 20px;
}

.status-oval {
  width: 80px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}

.active-oval {
  background-color: #4caf50; /* Green */
}

.pending-oval {
  background-color: #fdd835; /* Yellow */
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  background-color: #7b7b7b;
}

.accept-btn {
  padding: 10px 20px;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.accept-btn:hover {
  background-color: #4caf50; /* Green */
}

.reject-btn {
  padding: 10px 20px;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reject-btn:hover {
  background-color: #ff4444; /* Light Red */
}
</style>
